import React, { useState, useEffect, useRef } from "react";
import "./App.scss";
import Modal from "./Components/Modal";
import axios from "axios";

function App() {
  const [modalVisible, setModalVisible] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [param, setParam] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [waitingApproval, setWaitingApproval] = useState(false);
  const [transactionComplete, setTransactionComplete] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [amount, setAmount] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [merchantId, setMerchantId] = useState(null);
  const [merchantName, setMerchantName] = useState(null);
  const [tx_ref, setTx_ref] = useState(null);
  const [redirectTo, setRedirectTo] = useState();
  const [items, setItems] = useState();
  const mountedRef = useRef(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("mobileMoney");
  const [iframeUrl, setIframeUrl] = useState("https://cards.fanitepay.com/?amount=250000&currency=USD");

  const loadingSVG = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" fill="none" stroke="#FFFFFF" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </circle>
    </svg>
  );

  const validateInput = () => {
    const isValidInput = /^2567\d{8}$/.test(mobileNumber);

    if (isValidInput || mobileNumber === '') {
      setErrorMessage('');
      setIsButtonDisabled(false);
    } else {
      setErrorMessage('Enter Number starting with 2567....');
      setIsButtonDisabled(true);
    }
  };

  const handleInputChange = (e) => {
    setMobileNumber(e.target.value);
  };

  const handleBlur = () => {
    validateInput();
  };

  const checkTransactionStatus = async () => {
    try {
      const response = await axios.post("https://envoy.fanitepay.com/fntp/verify", {
        merchantId: merchantId,
        tx_ref: tx_ref,
      });

      const { status, amount, reference } = response.data;

      if (status === 'Pending') {
        setTransactionComplete(false);
      } else if (status === 'Success') {
        setTransactionComplete(true);

        setTimeout(() => {
          window.location.href = redirectTo;
          setModalVisible(false);
        }, 3000);
      }
    } catch (error) {
      // Handle error as needed
      console.error('Error checking transaction status:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (waitingApproval) {
        checkTransactionStatus();
      }
    }, 3000);

    return () => clearInterval(intervalId);
  }, [waitingApproval, param]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [param]);

  useEffect(() => {
    if (paymentData && paymentData[0]) {
      convertToUSD(paymentData[0].amount, paymentData[0].currency).then(amountUSD => {
        setIframeUrl(`https://cards.fanitepay.com/?amount=${amountUSD}&currency=USD`);
      });
    }
  }, [paymentData]);

  const fetchData = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const payrefParam = urlParams.get('payref');

      if (!payrefParam) {
        setModalVisible(true);
        return;
      }

      const response = await axios.get(`https://envoy.fanitepay.com/fntp/pay?payref=${payrefParam}`);

      if (!response || !response.data || response.data.length === 0) {
        setModalVisible(true);
        return;
      }

      const firstItem = response.data[0];
      setAmount(firstItem.amount);
      setName(firstItem.name);
      setEmail(firstItem.email);
      setMerchantId(firstItem.merchantId);
      setMerchantName(firstItem.merchantName);
      setRedirectTo(firstItem.redirectUrl);
      setItems(firstItem.items);
      setPaymentData(response.data);

      if (!param) {
        setParam(payrefParam);
      }

      setModalVisible(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setModalVisible(true);
    }
  };

  const handlePayButtonClick = async (e) => {
    e.preventDefault();

    if (!param) {
      setModalVisible(true);
      return;
    }

    setIsLoading(true);

    const postData = {
      payref: param,
      phone: mobileNumber,
      merchantId: merchantId,
      amount: amount,
      name: name,
      email: email,
      meta: param,
      orders: items
    };

    try {
      const response = await axios.post("https://payload.fanitepay.com/init", postData);

      if (!mountedRef.current) return;

      setTx_ref(response.data.tx_ref);
      setPaymentData(response.data);

      setWaitingApproval(true);
    } catch (error) {
      console.error('Error initiating payment:', error);
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const convertToUSD = async (amount, currency) => {
    try {
      const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
      const rates = response.data.rates;
      const conversionRate = rates[currency];
      const amountUSD = amount / conversionRate;
      return amountUSD.toFixed(2);
    } catch (error) {
      console.error('Error converting to USD:', error);
      return amount; // Fallback to the original amount if conversion fails
    }
  };

  return (
    <div className="App">
      <Modal show={modalVisible} close={() => setModalVisible(false)} title={param ? "Dynamic Title" : "Error"}>
        {param && !waitingApproval && (
          <main className="flex flex-col p-7 space-y-4 w-full">
            <section className="header container--row container__col-md-12" title="Payment" data-v-53ce5f6b data-v-47b9326c>
              <div className="header__details" data-v-53ce5f6b>
                <div className="merchant__details" data-v-53ce5f6b>
                  <img className="merchant__details-logo" src={paymentData && paymentData[0]?.logo} alt="merchant-logo" data-v-53ce5f6b />
                  <p className="merchant__details-name" data-v-53ce5f6b>
                    {merchantName}
                  </p>
                </div>
                <div className="customer__details" data-v-53ce5f6b>
                  <div className="customer__details-money" data-v-53ce5f6b>
                    <span data-v-53ce5f6b>{paymentData && paymentData[0]?.currency}</span>
                    <p data-v-53ce5f6b>{paymentData && paymentData[0]?.amount}</p>
                  </div>
                  <p className="customer__details-email" data-v-53ce5f6b>
                    {paymentData && paymentData[0]?.name}
                  </p>
                </div>
              </div>
              <div className="dcc__info" data-v-53ce5f6b>
                {/**/}
              </div>
            </section>
            
            <div className="flex justify-center items-center max-h-full">
              <div className="space-y-4 w-full h-full py-2">
                <div className="space-y-4 mt-1">
                  {activeTab === "mobileMoney" && (
                    <form className="space-y-8">
                      <div className="space-y-6">
                        <div className="flex flex-col space-y-1 relative overflow-visible">
                          <label htmlFor="mobile" className="flex space-x-1 items-center text-sm font-medium text-primary-dark"><div className="font-medium">Mobile Money Number</div></label>
                          <div className="relative">
                            <div className="w-10 overflow-hidden absolute left-3 top-3">
                              <img src='https://res.cloudinary.com/dr6nn6thq/image/upload/v1691354478/mobile_fba65p.png' width={34} height={34} alt='operator' />
                            </div>
                            <input
                              value={mobileNumber}
                              name="mobile"
                              onChange={handleInputChange}
                              onBlur={handleBlur}
                              placeholder="2567xxxxxxxx"
                              type="number"
                              className="appearance-none text-sm rounded-lg border border-gray-200 p-4 pb-3 w-full h-12 outline-primary placeholder:text-gray-300 focus:bg-white pl-14"
                              maxLength={12}
                            />
                            {errorMessage && (
                              <><br /><p className="text-red-500 text-sm">{errorMessage}</p></>
                            )}
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={handlePayButtonClick}
                        className="flex justify-center bg-peach-light rounded-lg p-3 space-x-1 text-sm text-white w-full font-medium opacity-95 hover:opacity-100"
                        disabled={isButtonDisabled || isLoading}
                      >
                        {isLoading ? (
                          <div className="flex items-center">
                            {loadingSVG}
                            <span className="ml-2">Processing...</span>
                          </div>
                        ) : (
                          <span className="mt-px block text-white">Pay {paymentData && paymentData[0]?.currency}&nbsp;{paymentData && paymentData[0]?.amount}</span>
                        )}
                      </button>
                    </form>
                  )}
                  {activeTab === "creditCard" && (
                      <div className="iframe-container">
                      <iframe 
                          src={iframeUrl} 
                          title="Credit Card Form"
                          width="100%" 
                          height="300px" 
                          style={{ overflow: 'hidden' }}
                          allowFullScreen
                          >
                      </iframe>
                  </div>
                  )}
                </div>
              </div>
            </div>
            <div className="tabs">
              {activeTab === "mobileMoney" ? (
                <button onClick={() => handleTabChange("creditCard")}
                style={{ backgroundColor: "#b4eeb9", color: "#13A052"  }} 
                className="flex justify-center rounded-lg p-3 w-full">
                  Credit Card</button>
              ) : (
                <button
                onClick={() => handleTabChange("mobileMoney")}
                style={{ backgroundColor: "#b4eeb9", color: "#13A052"  }}
                className="flex justify-center rounded-lg p-3 w-full"
              >Mobile Money</button>
              )}
            </div>
                      <div className="flex justify-center">
                        <p className="merchant__details-name" data-v-53ce5f6b><img src="https://res.cloudinary.com/dr6nn6thq/image/upload/v1704069290/logo_green_ipzlij.png" width={84} height={84} alt='securedby' /></p>
                      </div>
          </main>
        )}

        {waitingApproval && !transactionComplete && (
          <main className="flex flex-col p-7 space-y-4 w-full">
            <div className="flex justify-center items-center max-h-full">
              <div className="space-y-4 w-full h-full py-2">
                <div className="space-y-4 mt-1">
                  <div className="overflow-hidden px-8 flex justify-center">
                    <img alt="Loading" src="https://res.cloudinary.com/dr6nn6thq/image/upload/v1699995897/icons8-dots-loading_jhra07.gif" />
                  </div>
                  <form className="space-y-8">
                    <p className="text-sm leading-normal text-gray-500 flex justify-center">Enter Mobile Money PIN on your phone.</p>
                    <p className="text-sm leading-normal text-purple-700 flex justify-center">Waiting for Approval.</p>
                    <div className="flex justify-center">
                      <img src="https://res.cloudinary.com/dr6nn6thq/image/upload/v1699784620/fanite-logo_pkahti.png" width={84} height={84} alt='securedby' />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </main>
        )}

        {!param && !waitingApproval && (
          <main className="flex flex-col p-7 space-y-4 w-full">
            <div className="flex justify-center items-center max-h-full">
              <div className="space-y-4 w-full h-full py-2">
                <div className="space-y-4 mt-1">
                  <div className="overflow-hidden px-8 flex justify-center">
                    <img alt="Error" src="https://res.cloudinary.com/dr6nn6thq/image/upload/v1699994343/icons8-box-important_ixuyzi.gif" />
                  </div>
                  <form className="space-y-8">
                    <p className="text-sm leading-normal text-gray-500 flex justify-center">This payment link seems broken.</p>
                    <p className="text-sm leading-normal text-purple-700 flex justify-center">Please check again.</p>
                  </form>
                </div>
              </div>
            </div>
          </main>
        )}

        {transactionComplete && (
          <main className="flex flex-col p-7 space-y-4 w-full">
            <div className="flex justify-center items-center max-h-full">
              <div className="space-y-4 w-full h-full py-2">
                <div className="space-y-4 mt-1">
                  <div className="overflow-hidden px-8 flex justify-center">
                    <img alt="Congratulations" src="https://res.cloudinary.com/dr6nn6thq/image/upload/v1700002865/success_mffjbf.png" />
                  </div>
                  <form className="space-y-8">
                    <p className="text-sm leading-normal text-gray-500 flex justify-center">Congratulations!!</p>
                    <p className="text-sm leading-normal text-purple-700 flex justify-center">Payment is completed Successfully</p>
                  </form>
                </div>
              </div>
            </div>
          </main>
        )}
      </Modal>
    </div>
  );
}

export default App;
